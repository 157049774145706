<template>
  <div v-if="$can('all', 'customer')">
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$router.push({ name: 'customer-add', query: {editId: props.row.id }})">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-input
              v-model="pageLength"
              class="mx-1"
              type="number"
              @input="getCustomers"
            />
            <span class="text-nowrap "> of {{ totalRows }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="getCustomers"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 15,
      dir: false,
      currentPage: 1,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Parasut ID',
          field: 'id',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'E Mail',
          field: 'email',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'İsim',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Kısa İsim',
          field: 'short_name',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'İletişim türü',
          field: 'contact_type',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Vergi Dairesi',
          field: 'tax_office',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'TC Numarası',
          field: 'tax_number',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Ülke',
          field: 'country',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'İl',
          field: 'city',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'İlçe',
          field: 'district',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Adres',
          field: 'address',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Telefon',
          field: 'phone',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Fax',
          field: 'fax',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Yurt Dışı',
          field: 'is_abroad',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Arşiv',
          field: 'archived',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'İzlenme',
          field: 'untrackable',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'İban',
          field: 'iban',
          width: '200px',
          filterOptions: {
            enabled: true,
          },
        },
      ],
      rows: [],
      totalRows: null,
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters('customer', ['tableData']),
  },
  created() {
    this.getCustomers()
    if (!this.$can('all', 'customer')) {
      this.$router.push({ name: 'error-404' })
    }
  },
  methods: {
    ...mapActions('customer', ['deleteCustomer']),
    deleteData(id) {
      this.deleteCustomer(id).then(() => {
        this.getCustomers()
      })
    },
    getCustomers() {
      this.$http.get(`/parasut/customer/?pageNumber=${this.currentPage}&pageSize=${this.pageLength}`).then(res => {
        this.rows = []
        const customers = res.data.data.data
        customers.forEach(element => {
          // console.log(res)
          this.rows.push({ id: element.id, ...element.attributes })
          this.totalRows = res.data.data.meta.total_count
        })
      })
    },
  },
}
</script>
